import { useState, useEffect } from "react"
import Navbar from "../../components/Navbar"
import PageTitle from "../../components/PageTitle"
import { allFirstDiv } from "../../components/DesignStandardize"

const HolidayCRUD = () => {
  const [holidays, setHolidays] = useState([])
  const [newHoliday, setNewHoliday] = useState({ Date: "", Description: "" })
  const [editingHoliday, setEditingHoliday] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const apiUrl = process.env.REACT_APP_API_BASE_URL

  useEffect(() => {
    fetchHolidays()
  }, [])

  const fetchHolidays = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${apiUrl}/holiday`)
      if (!response.ok) throw new Error("Failed to fetch holidays")
      const data = await response.json()
      setHolidays(data)
    } catch (err) {
      setError("Failed to load holidays")
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    if (editingHoliday) {
      setEditingHoliday({ ...editingHoliday, [name]: value })
    } else {
      setNewHoliday({ ...newHoliday, [name]: value })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (editingHoliday) {
        await updateHoliday(editingHoliday)
      } else {
        await createHoliday(newHoliday)
      }
      fetchHolidays()
      setNewHoliday({ Date: "", Description: "" })
      setEditingHoliday(null)
    } catch (err) {
      setError("Failed to save holiday")
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const createHoliday = async (holiday) => {
    const response = await fetch(`${apiUrl}/holiday`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(holiday),
    })
    if (!response.ok) throw new Error("Failed to create holiday")
  }

  const updateHoliday = async (holiday) => {
    const response = await fetch(`${apiUrl}/holiday/${holiday.HolidayID}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(holiday),
    })
    if (!response.ok) throw new Error("Failed to update holiday")
  }

  const deleteHoliday = async (id) => {
    setLoading(true)
    try {
      const response = await fetch(`${apiUrl}/holiday/${id}`, { method: "DELETE" })
      if (!response.ok) throw new Error("Failed to delete holiday")
      fetchHolidays()
    } catch (err) {
      setError("Failed to delete holiday")
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Navbar />
      <PageTitle title="Holiday Management" />
      <div className={`${allFirstDiv} min-h-screen bg-[#F5F5F5] py-12 px-4 sm:px-6 lg:px-8`}>
        <div className="max-w-7xl mx-auto space-y-8">
          <h1 className="text-4xl font-extrabold text-[#2D2D2D] text-center mt-5">Holiday Management</h1>

          {/* Form Card */}
          <div className="bg-white rounded-2xl overflow-hidden border border-gray-200 shadow-[0_8px_30px_rgb(0,0,0,0.12)] backdrop-blur-sm">
            <div className="p-6 sm:p-8">
              <h2 className="text-xl font-semibold text-[#2D2D2D] mb-6">
                {editingHoliday ? "Edit Holiday" : "Add New Holiday"}
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="Date" className="block text-sm font-medium text-gray-700">
                    Date
                  </label>
                  <input
                    type="date"
                    id="Date"
                    name="Date"
                    value={editingHoliday ? editingHoliday.Date : newHoliday.Date}
                    onChange={handleInputChange}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#2D2D2D] focus:border-[#2D2D2D]"
                  />
                </div>
                <div>
                  <label htmlFor="Description" className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <input
                    type="text"
                    id="Description"
                    name="Description"
                    value={editingHoliday ? editingHoliday.Description : newHoliday.Description}
                    onChange={handleInputChange}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#2D2D2D] focus:border-[#2D2D2D]"
                  />
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full px-4 py-2 bg-[#2D2D2D] text-white rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2D2D2D] transition duration-200 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? "Saving..." : editingHoliday ? "Update Holiday" : "Add Holiday"}
                </button>
              </form>
            </div>
          </div>

          {/* Holidays List */}
          <div className="bg-white rounded-2xl overflow-hidden border border-gray-200 shadow-[0_8px_30px_rgb(0,0,0,0.12)] backdrop-blur-sm">
            <div className="p-6 sm:p-8">
              <h2 className="text-xl font-semibold text-[#2D2D2D] mb-6">Holidays List</h2>
              {error && <p className="text-red-500 mb-4">{error}</p>}
              {loading ? (
                <p>Loading holidays...</p>
              ) : (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Description
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {holidays.map((holiday) => (
                        <tr key={holiday.HolidayID}>
                          <td className="px-6 py-4 whitespace-nowrap">{holiday.Date}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{holiday.Description}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button
                              onClick={() => setEditingHoliday(holiday)}
                              className="text-indigo-600 hover:text-indigo-900 mr-4"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => deleteHoliday(holiday.HolidayID)}
                              className="text-red-600 hover:text-red-900"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HolidayCRUD

